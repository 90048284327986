import { tok_logined_op } from "@/utils/HttpUtil";

//获取轨迹未读信息列表
export async function getTraceMsgUnreadList(params: {}) {
  return tok_logined_op(50096, params, (data) => {
    return data;
  });
}

//标记未读轨迹信息
export async function MarkTraceMsg(params: {}) {
    return tok_logined_op(50097, params, (data) => {
      return data;
    });
  }