import { uid_auth } from './utils/uid_auth';

export default function access(initialState: {
  currentUser?: API.CurrentUser | undefined;
}) {
  const { currentUser } = initialState || {};
  //下边列表内容为想要某类角色看见某类型的页/组，将其添加到对应的数组中
  // const adminList = [
  //   "admin",
  //   "superMgr",
  //   "serviceCer",
  //   "serviceArea",
  //   "saler",
  //   "finance",
  //   "account",
  //   "deliveryCenter",
  // ];
  // const superMgrList = [
  //   "admin",
  //   "superMgr",
  //   "serviceCer",
  //   "serviceArea",
  //   "saler",
  //   "finance",
  //   "account",
  //   "deliveryCenter",
  // ];
  // const serviceCerList = [
  //   "admin",
  //   "superMgr",
  //   "serviceCer",
  //   "serviceArea",
  //   "saler",
  //   "finance",
  //   "account",
  //   "deliveryCenter",
  // ];
  // const serviceAreaList = [
  //   "admin",
  //   "superMgr",
  //   "serviceCer",
  //   "finance",
  //   "account",
  //   "deliveryCenter",
  // ];
  // const salerList = [
  //   "admin",
  //   "superMgr",
  //   "serviceCer",
  //   "serviceArea",
  //   "finance",
  //   "account",
  //   "deliveryCenter",
  // ];
  // const financeList = [
  //   "admin",
  //   "superMgr",
  //   "serviceCer",
  //   "serviceArea",
  //   "saler",
  //   "finance",
  //   "account",
  //   "deliveryCenter",
  // ];
  // const accountList = [
  //   "admin",
  //   "superMgr",
  //   "serviceCer",
  //   "serviceArea",
  //   "saler",
  //   "finance",
  //   "account",
  //   "deliveryCenter",
  // ];
  // const guestList = [
  //   "admin",
  //   "superMgr",
  //   "serviceCer",
  //   "serviceArea",
  //   "saler",
  //   "finance",
  //   "account",
  //   "guest",
  //   "deliveryCenter",
  // ];
  // const unsubscribeList = ["unsubscribe"];

  console.log(currentUser);

  const auth_data = uid_auth(
    process.env.REACT_APP_CONFIG_ENV,
    currentUser?.uid,
  );
  console.log(auth_data);
  return {
    admin: currentUser,
    account_payable:
      currentUser && auth_data.some((item) => item === 'sattlement_payable'),
    account_receivable:
      currentUser && auth_data.some((item) => item === 'sattlement_receivable'),
    trunkRoadautoTrace:
      currentUser && auth_data.some((item) => item === 'trunkRoad_autoTrace'),
    client: currentUser && auth_data.some((item) => item === 'client_block'),
    client_verify:
      currentUser &&
      auth_data.some((item) => item === 'client_block_infoVerify'),
    insurance:
      currentUser && auth_data.some((item) => item === 'insurance_page'),
    changePrice:
      currentUser &&
      auth_data.some((item) => item === 'allow_orderPay_price_change'),
    changeTask: currentUser && auth_data.some((item) => item === 'change_task'),
    accessType: currentUser?.access, //类型（谨慎注释）
  };
}
